import "animate.css"
import "./styles/global.scss"
import "./App.css"
import CryptoSwap from "./components/CryptoSwap";
import logo from "./assets/images/logo.png"
import twitter from "./assets/icons/twitter.svg"
import {ConnectKitButton} from "connectkit";
import {useState} from "react";

function App() {
  return (
    <main>
      <div className="gradient"></div>
      <div className="header">
        <div className='header-name'>
          <img src={logo} alt="logo" className="logo"/>
          <h1>Neptuna Finance</h1>
        </div>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </div>
      <p>
        Attention! We are running our project in the Beta version. Support: <a href="mailto:support@neptuna.finance">support@neptuna.finance</a>
      </p>
      <CryptoSwap/>
      {/*<div className='socials'>*/}
      {/*  <a target='_blank' rel='noreferrer noopener' href="https://twitter.com/LisoFinance">*/}
      {/*    <img src={twitter} alt='twitter'/>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </main>
  );
}

export default App;
